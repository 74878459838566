import React, {useEffect, useState} from "react";
import citizenslogo from "../../images/citizenslogo.png";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  faAddressBook,
  faBullhorn,
  faCookie,
  faDesktop,
  faHome,
  faPenSquare,
  faSearch,
  faTable, faTrash
} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Typography} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import LoginButton from "../general/login-button/LoginButton";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import {useRoles} from "../../config/authentication/RoleProvider";
import {groupedByRole} from "../../util/constants";

const AppBarStyled = styled("AppBar")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  width: "100%",
  background: "#0066a1 !important",
  backgroundColor: "#0066a1 !important",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerToolBarStyles = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0 8px",
  height: theme.spacing(7),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: 300,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerStyled = styled("Drawer", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#0066a1 !important",
  overflowX: "hidden",
  flexShrink: 0,
  marginLeft: "-1rem",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppBarSpacerStyles = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export function Navigation(props) {
  const history = props.history;
  const [lightMode, setLightMode] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectionIndex] = useState(0);
  const navigate = useNavigate();
  //Logoff menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [username, setUsername] = useState(null);
  const [upperSection, setUpperSection] = useState([]);
  const [navigationIconsList, setNavigationIconsList] = useState([]);
  const roles = useRoles();
  const settings = ["Account", "Logout"];

  const PATH_DETAILS = {
    "/home": { label: "Home", icon: faHome },
    "/quote-search": { label: "Retrieve", icon: faSearch },
    "/dashboard": { label: "Dashboard", icon: faDesktop },
    "/throw-away-quotes": { label: "Throw Away Quotes", icon: faTrash },
    "/quote-submit": { label: "Submit", icon: faTable },
    "/vendor-renewal-view": { label: "Renewal Config", icon: faCookie },
    "/campaign": { label: "Campaign", icon: faBullhorn },
    "/appointments-override-view": { label: "Appointments Override", icon: faPenSquare }
  };

  useEffect(() => {
   const drawerSection = createUpperDrawerSection(roles);
    setUpperSection(drawerSection);
    setNavigationIconsList(JSON.parse(JSON.stringify(drawerSection))
        .map(item => item.to))
  }, [roles])

  const createUpperDrawerSection = (userRole) => {
    const paths = groupedByRole[userRole] || [];
    paths.unshift('/home');
    return paths.map(path => {
      const pathDetails = PATH_DETAILS[path];
      if (!pathDetails) {
        console.warn(`No details found for path: ${path}`);
        return null;
      }
      const {label, icon} = pathDetails;
      return {label, kind: icon, to: path};
    }).filter(item => item !== null);
  };

  const handleListItemClick = (event, index) => {
    setSelectionIndex(index);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    const pathName = window.location.pathname;
    if (isAuthenticated && instance) {
      setUsername(instance?.getActiveAccount()?.name);
      const index = navigationIconsList.indexOf(pathName);
      setSelectionIndex(index);
      if (pathName && pathName !== '/unauthorized') {
        navigateToHome();
      }
    } else {
      setUsername(null);
      setSelectionIndex(0);
    }
  }, [isAuthenticated, instance]);


  useEffect(() => {
    const pathName = window.location.pathname;
    const index = JSON.parse(JSON.stringify(navigationIconsList)).indexOf(pathName);
    setSelectionIndex(index);
  }, [window.location.pathname]);

  const navigateToHome = () => {
    navigate("/home");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    if (event?.target?.innerHTML.includes('Logout')) {
      instance.logoutRedirect();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        fontFamily: `CircularXXWeb,"Segoe UI","Segoe",Tahoma,Helvetica,Arial,sans-seri`,
      }}
    >
      <AppBarStyled>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "left",
            width: "100%",
            height: "65px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "34%",
              height: "65px",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon style={{ color: "#fff" }} />
            </IconButton>
            <IconButton
                color="primary"
                aria-label="Home"
                onClick={navigateToHome}
            >
              <img
                  style={{
                    width: "150px",
                    height: "40px",
                  }}
                  src={citizenslogo}
              />
            </IconButton>
            <FormLabel style={{ color: "#fff" , marginLeft: '3rem', fontWeight: 'bolder', fontSize: 'larger'}}>Integration Hub</FormLabel>
          </div>

          <div
            style={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingLeft: "1rem",
              height: "65px",
              width: "20%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderLeft: "1px solid #99A3A4",
                paddingLeft: "1rem",
                alignItems: "center",
              }}
            >
              {isAuthenticated && (
                <div
                  style={{
                    fontSize: "12px",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "0.50rem",
                  }}
                >
                  <Typography
                    variant="string"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "16px",
                    }}
                  >
                    {`Welcome ${username?.split(" ")[0]}`}
                  </Typography>
                </div>
              )}
              <Box sx={{ flexGrow: 0 }}>
                {isAuthenticated && (
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar>
                        {username?.split(" ")?.map((n) => n[0]?.toUpperCase())}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                )}
                {!isAuthenticated && <LoginButton />}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </div>
      </AppBarStyled>
      <DrawerStyled open={open}>
        <DrawerToolBarStyles />
        {open && (
          <DrawerHeader>
            <IconButton onClick={handleDrawer}>
              <ChevronLeftIcon style={{ color: "#fff" }} />
            </IconButton>
          </DrawerHeader>
        )}
        <Divider />
        <List>
          {upperSection.map((item, index) => {
            return (
              <ListItem
                key={item.label}
                component={Link}
                to={item.to}
                style={
                  selectedIndex === index
                    ? {
                        marginLeft: "1rem",
                        borderLeft: "4px solid #fff",
                        height: "50px",
                      }
                    : { marginLeft: "4px", height: "50px" }
                }
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemButton>
                  <ListItemIcon
                    style={{
                      marginLeft: `${selectedIndex === index ? "-1rem" : "0"}`                      
                    }}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={item.kind}
                      style={{ color: "#FFFFFF" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    style={{ color: "#fff" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </DrawerStyled>
      <main style={{ flexGrow: 1, height: "100vh", overflow: "auto" }}>
        <AppBarSpacerStyles />
        {props.children}
      </main>
    </div>
  );
}

export default Navigation;
