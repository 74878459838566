import React, {useState} from "react";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import {Box, Button, Grid, TextField, Zoom} from '@mui/material';
import AppService from "../../service/AppService";
import Swal from "sweetalert2";
import {parseError} from "../../config/react-routing";
import Loader from "../general/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import MaterialReactTable from "../quote-request/MaterialReactTable";
import DialogContent from "@mui/material/DialogContent";
import ReactJson from "react18-json-view";
import Dialog from "@mui/material/Dialog";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export default function ThrowAwayQuoteRequestPage(props) {

    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [error, setError] = useState();
    const {execute, isLoading} = useFetchWithMsal(loginRequest);
    const [open, setOpen] = useState(false);
    const [jsonPayload, setJsonPayload] = useState(null);
    const handleOpenModal = (json) => {
        setJsonPayload(json);
        setOpen(true);
    };

    const formatDate = (date) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        return new Date(date).toLocaleTimeString('en-US', options);
    };
    const handleClose = () => {
        setOpen(false);
        setJsonPayload(null);
    };
    const [searchForm, setSearchForm] = useState({
        createdAfter: "",
        createdBefore: "",
        createdAfterDate: "",
        createdBeforeDate: "",
    });

    const formatDateAndTime = (cell) => {
        const date = new Date(cell.getValue());
        const formattedDate = date.toISOString().split('T')[0];
        const formattedTime = formatDate(date);

        return (
            <div>
                {formattedDate} <span style={{marginLeft: '8px'}}> {formattedTime}</span>
            </div>
        );
    };

    const headerColumns = [
        {
            header: '#',
            id: 'serialNumber',
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => ((pageNumber) * 25) + row.index + 1,
        },
        {
            header: "CREATED AT",
            accessorKey: "ranAt",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({cell}) => {
                return formatDateAndTime(cell)
            },
        },
        {
            header: "QUOTE REQUEST",
            accessorKey: "quotePayload",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => {
                return (
                    <div style={{display: "flex", alignItems: "center", gap: "3.5rem"}}>
                        <FontAwesomeIcon icon={faEye} size="lg" style={{cursor: "pointer"}}
                                         onClick={() => handleOpenModal(row.original.quotePayload)}/>
                        <FontAwesomeIcon
                            icon={faDownload}
                            size="lg"
                            onClick={() => handleDownloadJSON('quote_request' , row.original.quotePayload)}
                            style={{cursor: "pointer"}}
                        />
                    </div>)
            },
        },
        {
            header: "VALIDATION RESPONSE",
            accessorKey: "validationResponse",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => {
                return (
                    <div style={{display: "flex", alignItems: "center", gap: "3.5rem"}}>
                        <FontAwesomeIcon icon={faEye} size="lg" style={{cursor: "pointer"}}
                                         onClick={() => handleOpenModal(row.original.validationResponse)}/>
                        <FontAwesomeIcon
                            icon={faDownload}
                            size="lg"
                            onClick={() => handleDownloadJSON('validation_response', row.original.validationResponse)}
                            style={{cursor: "pointer"}}
                        />
                    </div>)
            },
        }
    ];

    const handleDownloadJSON = (name, data) => {
        const blob = new Blob([JSON.stringify(JSON.parse(data), null, 2)], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = name + ".json";
        link.click();
        URL.revokeObjectURL(url);
    }

    const getDetails = async (criteria) => {
        setError(null);
        let searchCriteria = Object.assign({}, criteria);
        if (searchCriteria.createdAfterDate && searchCriteria.createdBeforeDate) {
            searchCriteria.createdAfter = searchCriteria.createdAfterDate + " 00:00:00.000"
            searchCriteria.createdBefore =
                searchCriteria.createdBeforeDate + " 23:59:59.000"
        }
        delete searchCriteria.createdAfterDate;
        delete searchCriteria.createdBeforeDate;
        AppService().getThrowAwayQuotes(
            execute,
            JSON.stringify(searchCriteria)
        )
            .then((data) => {
                setTableData(data?.searchResult);
                setTotalRecords(data?.numOfRecords);
                setPageNumber(data?.pageNumber);
                setSearchForm({
                    ...searchForm,
                    pageNumber: data?.pageNumber
                });
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get throw away results",
                    text: parseError(err),
                    showConfirmButton: true,
                });
            });
    };

    const handleUserInput = (event) => {
        setSearchForm({...searchForm, [event.target.name]: event.target.value});
    };
    const handleReset = () => {
        setSearchForm({
            createdAfter: "",
            createdBefore: "",
            createdAfterDate: "",
            createdBeforeDate: "",
        });
        setTableData([]);
        setTotalRecords(0);
        setPageNumber(0);
        setError(null);
    };

    const nextPage = async (newPage) => {
        setPageNumber(newPage);
        getDetails({...searchForm, pageNumber: newPage});
    };

    const handleSearch = (event) => {
        setTableData([]);
        setError("");
        if (inputValidations()) {
            getDetails(searchForm);
        }
    };

    const inputValidations = () => {
        let errorMessage = [];
        let validationPassed = true;
        if (
            !(
                searchForm.createdBeforeDate ||
                searchForm.createdAfterDate
            )
        ) {
            errorMessage.push("Input needed!");
            validationPassed = false;
        }
        if (
            (searchForm.createdAfterDate && !searchForm.createdBeforeDate) ||
            (!searchForm.createdAfterDate && searchForm.createdBeforeDate)
        ) {
            errorMessage.push("Both date criteria needed to proceed!");
            validationPassed = false;
        }

        if (
            searchForm.createdAfterDate &&
            searchForm.createdBeforeDate &&
            searchForm.createdAfterDate > searchForm.createdBeforeDate
        ) {
            errorMessage.push(
                "Creation End Date cannot be greator than Creation start Date!"
            );
            validationPassed = false;
        }
        if (
            !searchForm.createdBeforeDate &&
            !searchForm.createdAfterDate
        ) {
            errorMessage.push(
                "Please provide Created After and Created Before Dates"
            );
            validationPassed = false;
        }
        if (!validationPassed) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Oops!",
                text: errorMessage.toString(),
                showConfirmButton: true,
            });
        }
        return validationPassed;
    };

    return (
        <Box display="flex" flexDirection="column" overflowY="auto">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "22px",
                    fontWeight: 700,
                    marginBottom: "1rem",
                }}
            >
                Search Throw Away Quotes (Validation Failed Quotes):
            </div>
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>

                <Grid item xs={12} s={6} md={4} lg={2}>
                    <TextField
                        required
                        onChange={handleUserInput}
                        value={searchForm?.createdAfterDate}
                        id="createdAfterDate"
                        name="createdAfterDate"
                        label="Created After"
                        type="date"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} s={6} md={4} lg={2}>
                    <TextField
                        required
                        value={searchForm?.createdBeforeDate}
                        onChange={handleUserInput}
                        id="createdBeforeDate"
                        name="createdBeforeDate"
                        label="Created Before"
                        type="date"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} s={6} md={4} lg={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        style={{background: "grey", marginLeft: "0.5rem"}}
                        onClick={() => {
                            setError(null);
                            handleReset();
                        }}
                        size="large"
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                }}
            >
                {isLoading && <Loader/>}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Zoom}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    {jsonPayload && <ReactJson displaySize="expanded" src={JSON.parse(jsonPayload)} collapsed={2}/>}
                </DialogContent>
            </Dialog>
            {tableData &&
                <MaterialReactTable
                    headers={headerColumns}
                    data={tableData}
                    handleChangePage={nextPage}
                    totalRows={totalRecords}
                    pageNumberToUse={pageNumber}
                    enableRowActions={false}
                />}
        </Box>
    );
}